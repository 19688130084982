<template>
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="centered">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="complete__icon">
								<i class="icon icon-complete"></i>
							</div>
							<h3 class="textbox__title textbox__title--large">
								{{t('10069')}}
							</h3>
							<p class="textbox__description">
								<strong class="black">{{userData.nick_nm}}</strong>{{t('10070')}}
							</p>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<router-link to="/rooms" custom v-slot="{ navigate }">
				<button type="button" class="btn btn-confirm" @click="navigate">{{t('10071')}}</button>
			</router-link>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>


<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup() {
		const store = useStore();
		const { t }= useI18n() //번역필수 모듈
		return {
			userData: computed(() => store.state.userData),
			t,
			i18n
		}
	},
}
</script>